import * as React from "react"
import Seo from "../components/Seo"
import Layout from "../components/Layout"
import {getImage, StaticImage} from "gatsby-plugin-image"
import {graphql, Link, useStaticQuery} from "gatsby"
import {BgImage} from "gbimage-bridge"
import {useEffect, useState} from "react";
import Icon from "../components/presentation/Icon";
import {faEnvelope, faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";
import {faInstagram, faLinkedinIn} from "@fortawesome/free-brands-svg-icons";

function FullPage({children}){
    return (
        <div style={{
            position: "relative",
            width: "100%",
            overflow: "hidden",
            background: "linear-gradient(135deg, #E91E63 0%, #039BE5 100%)"
        }}>
            {children}
        </div>
    )
}

function MovingBackground({image}){

    const [pos, setPos] = useState([0,0,0])

    useEffect(() => {

        const timerId = setInterval(() => {
            setPos(p => {
                const i = p[0]
                const x = Math.cos(i * 2 * Math.PI / 1500) * 20
                const y = Math.sin(i * 2 * Math.PI / 1500) * 20

                return [i + 1, x, y]
            })
        }, 10)

        return () => {
            clearInterval(timerId)
        }

    })

    return (<BgImage
        image={image}
        Tag="div"
        style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            transform: `scale(1.1) translateX(${pos[1]}px) translateY(${pos[2]}px)`
        }}
        alt="Legal Software Effect"
    />)
}

const Index = ({ data, location }) => {
    const { lightsImage } = useStaticQuery(
        graphql`
          query {
            lightsImage: file(relativePath: { eq: "LightsBackgroundEffect.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        `
    );

    const image = getImage(lightsImage)

    return (
        <Layout>
            <Seo />
            <FullPage>
                <div style={{zIndex: 10}}>
                    <MovingBackground image={image} />
                </div>
                <div
                    style={{
                        position: "relative",
                        width: "100%",
                        minHeight: "100vh",
                        zIndex: 20,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <div style={{
                        maxWidth: "32rem",
                        padding: "2rem 1rem",
                        textAlign: "center",
                        color: "rgba(255,255,255,0.9)"
                    }}>
                        <StaticImage
                            layout="constrained"
                            src="../images/LegalSoftware-LS-lightshadow.png"
                            width={480}
                            height={480}
                            placeholder="blurred"
                            alt="Legal Software Logo"
                        />
                        <h1>Legal Software</h1>
                        <h5 className="b3">Wir entwickeln digitale Plattformen und SaaS-Lösungen, um die Rechtsbranche grundlegend zu transformieren.</h5>
                        <h6 className="b3">
                            <Icon icon={faMapMarkerAlt} left/> München
                        </h6>

                        <div className="b3">
                            <Link to="/blog" style={{marginRight: 10}}>
                                <button> Blog </button>
                            </Link>

                            <Link to="/about" style={{marginRight: 10}}>
                                <button> Mehr erfahren </button>
                            </Link>
                        </div>
                        <div className="b3">
                            {/*
                               style={{margin: "0 10px", color: "rgba(255,255,255,0.8)", backgroundColor: "#1e88e5"}}
                              */}
                            <a href="https://www.linkedin.com/company/legalsoftware" target="_blank" className="button social light"
                               style={{margin: "0 10px"}}
                            >
                                <Icon size="lg" icon={faLinkedinIn}/>
                            </a>
                            <a href="https://www.instagram.com/legal.software/" target="_blank" className="button social light"
                               style={{margin: "0 10px"}}>
                                <Icon size="lg" icon={faInstagram}/>
                            </a>
                            <a href="mailto:info@legalsoftware.io" target="_blank" className="button social light"
                               style={{margin: "0 10px"}}>
                                <Icon size="lg" icon={faEnvelope}/>
                            </a>
                        </div>
                    </div>
                </div>
            </FullPage>
        </Layout>
    )
}

export default Index